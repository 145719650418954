<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                </b-card>
            </template>
            <b-card class="text-center">
                <h2>
                    <font-awesome-icon
                        icon="bell"
                        class="mr-2"
                    ></font-awesome-icon>
                    Notifications
                </h2>
                <div class="mt-3">
                    <router-link
                        is="button"
                        class="sc-btn pill mr-2"
                        @click="$router.push({ name: 'admin_form', params: { formKey: 'add-notification' } })"
                    >
                        <font-awesome-icon
                            class="mr-2"
                            icon="plus-circle"
                        ></font-awesome-icon>
                        Add
                    </router-link>
                    <button
                        class="sc-btn pill"
                        @click="arrangeable = !arrangeable"
                    >
                        <font-awesome-icon
                            class="mr-2"
                            icon="sort"
                        ></font-awesome-icon>
                        Sort
                    </button>
                </div>
            </b-card>
            <b-card
                class="my-2"
                title="Filter"
                title-tag="h5"
            >
                <b-checkbox-group
                    class="mt-3"
                    v-model="show"
                >
                    <b-check
                        @change="fetchNotifications()"
                        value="enabled"
                    >
                        Enabled
                    </b-check>
                    <b-check
                        @change="fetchNotifications()"
                        value="disabled"
                    >
                        Disabled
                    </b-check>
                </b-checkbox-group>
            </b-card>
            <b-list-group v-if="loadedNotifications.length">
                <b-list-group-item variant="secondary">
                    <font-awesome-icon class="mr-2" icon="bullhorn"></font-awesome-icon>
                    Notifications
                </b-list-group-item>
                <Container
                    @drop="onDrop($event)"
                    non-drag-area-selector=".field"
                    drag-class="form-ghost"
                    drop-class="form-ghost-drop"
                    :drop-placeholder="dropPlaceholderOptions"
                    :class="{ isActive: true }"
                >
                    <Draggable
                        v-for="(notification, idx) in loadedNotifications"
                        :drag-not-allowed="!arrangeable"
                        :key="idx"
                    >
                        <b-list-group-item
                            :key="notification.id"
                        >
                            <span
                                v-if="arrangeable"
                                class="column-drag-handle"
                                style="float:left; margin-right: 5px;"
                            >&#x2630;</span>
                            <div class="float-right">
                                <b-button
                                    @click="toggleEnabled(notification)"
                                    variant="link"
                                >
                                    <font-awesome-icon
                                        v-if="notification.enabled"
                                        icon="toggle-on"
                                    ></font-awesome-icon>
                                    <font-awesome-icon
                                        v-else
                                        icon="toggle-off"
                                    ></font-awesome-icon>
                                </b-button>
                                <router-link
                                    is="b-button"
                                    variant="link"
                                    :to="{ name: 'admin_form', params: { formKey: 'edit-notification' }, query: { id: notification.id }}"
                                >
                                    <font-awesome-icon
                                        icon="pencil-alt"
                                    ></font-awesome-icon>
                                </router-link>
                                <b-button
                                    @click="confirmDeleteNotification(notification)"
                                    variant="link"
                                >
                                    <font-awesome-icon
                                        icon="trash-alt"
                                    ></font-awesome-icon>
                                </b-button>
                            </div>
                            <b>
                                <font-awesome-icon
                                    v-if="notification.icon"
                                    :icon="notification.icon"
                                ></font-awesome-icon>
                                {{ notification.caption }}
                            </b>
                            <div v-if="notification.content != null">
                                <p
                                    v-for="(p, idx) in notification.content.split('\n')"
                                    :key="idx"
                                >
                                    {{ p }}
                                </p>
                            </div>
                            <div v-else></div>
                            <a :href="notification.link" is="b-button" variant="outline-primary">
                                <font-awesome-icon :icon="(notification.link_icon) ? notification.link_icon : 'link'"></font-awesome-icon>
                                {{ notification.link_title }}
                            </a>
                        </b-list-group-item>
                    </Draggable>
                </Container>
            </b-list-group>
            <b-list-group v-else>
                <b-list-group-item variant="secondary">
                    <font-awesome-icon class="mr-2" icon="bell"></font-awesome-icon> Notifications
                </b-list-group-item>
                <b-list-group-item>
                    <font-awesome-icon icon="info-circle"></font-awesome-icon>
                    <span class="m-3">No notifications to see here!</span>
                </b-list-group-item>
            </b-list-group>
        </b-skeleton-wrapper>
    </b-container>
</template>
<script>
import { Container, Draggable } from "vue-dndrop"
import { mapState } from "vuex"

export default {
    components: { Container, Draggable },
    mounted() {
        this.loading = true;
        this.fetchNotifications();
    },
    data() {
        return {
            arrangeable             : false,
            dropPlaceholderOptions  : {
                className: "drop-preview",
                animationDuration: "150",
                showOnTop: true
            },
            loading                 : false,
            loadedNotifications     : [],
            show                    : ["enabled"]
        }; 
    },
    computed: mapState(["notifications"]),
    methods: {
        fetchNotifications() {
            this.loading = true;
            this.$api.get(
                `/api/notifications/admin/list`,
                {
                    params: { get: this.show.join(",") }
                }
            ).then(({ data }) => {
                this.loadedNotifications = data.records;
                this.loading             = false;
            });
        },
        toggleEnabled({ id, enabled }) {
            this.$api.post(
                `/api/notifications/admin/change-enabled`,
                {
                    id     :  id,
                    enabled: !enabled
                }
            ).then(({ data }) => {
                if (data.success) {
                    this.$bvToast.toast(data.message, {
                        title         : "Successfully Changed!",
                        variant       : "success",
                        autoHideDelay : 5000,
                        appendToast   : true
                    });
                    this.fetchNotifications();
                } else {
                    this.$bvToast.toast(data.message, {
                        title         : "Change Failed!",
                        variant       : "danger",
                        autoHideDelay : 5000,
                        appendToast   : true
                    });
                }
            });
        },
        deleteNotification({ id }) {
            this.$api.post(
                `/api/notifications/admin/remove`,
                {
                    id : id
                }
            ).then(({ data }) => {
                if (data.success) {
                    this.$bvToast.toast(data.message, {
                        title         : "Successfully Removed!",
                        variant       : "success",
                        autoHideDelay : 5000,
                        appendToast   : true
                    });
                    this.fetchNotifications();
                } else {
                    this.$bvToast.toast(data.message, {
                        title         : "Remove failed!",
                        variant       : "danger",
                        autoHideDelay : 5000,
                        appendToast   : true
                    });
                }
            });
        },
        confirmDeleteNotification(notification) {
            this.$bvModal.msgBoxConfirm(
                "Please confirm that you want to delete this notification (Caption: \"" +
                    notification.caption + "\").",
                {
                    title           : "Please confirm to delete notification",
                    size            : "sm",
                    buttonSize      : "sm",
                    okVariant       : "danger",
                    okTitle         : "YES",
                    cancelTitle     : "NO",
                    footerClass     : "p-2",
                    hideHeaderClose : false,
                    centered        : true
                }
            ).then(confirmed => {
                if (confirmed) {
                    this.deleteNotification(notification);
                }
            })
            .catch(err => {
                this.$bvToast.toast(err, {
                    title         : "Remove failed!",
                    variant       : "danger",
                    autoHideDelay : 5000,
                    appendToast   : true
                });
            });
        },
        onDrop(dragResult) {
            const { removedIndex, addedIndex, payload } = dragResult;
            if (removedIndex === null && addedIndex === null) return;

            let itemToAdd = payload;
            if (removedIndex !== null) {
                itemToAdd = this.loadedNotifications.splice(removedIndex, 1)[0];
            }
            if (addedIndex !== null) {
                this.loadedNotifications.splice(addedIndex, 0, itemToAdd);
            }
            this.$api.post(
                `/api/notifications/admin/saveNotificationOrder`,
                {
                    order: this.loadedNotifications.map((notification) => `${notification.id}`).join(";")
                }
            ).then((response) => {
                console.log(response.data);
            });
        }
    }
}
</script>